import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueResource from 'vue-resource';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueLazyload from 'vue-lazyload'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueResource);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueLazyload);

Vue.config.productionTip = false;

new Vue({
  router,
  VueResource,
  store,
  render: function (h) { return h(App) }
}).$mount('#app');

<template>
  <VueSlickCarousel v-bind="settings">
    <div
      v-for="(item, index) in homeData.Carousel"
      :key="index"
      class="slide"
    >
      <img
        :src="dataUrl + item.Image"
        width="100%"
        :alt="item.Image"
      />
      <div
        class="carousel-description"
        :title="'Gehe zu \'' + item.Headline + '\''"
        @click="$router.push({ path: item.Url })"
      >
        <div class="decription-link">
          {{ item.Headline }}<i class="fas fa-link" />
        </div>
      </div>
    </div>
    <template #prevArrow="arrowOption">
      <div class="custom-arrow" />
    </template>
    <template #nextArrow="arrowOption">
      <div class="custom-arrow" />
    </template>
  </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    homeData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      settings: {
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 6500,
        draggable: true,
        edgeFriction: 0.35,
        fade: false,
        infinite: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 2000
      },
      arrowOption: {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide {
  position: relative;
  width: 100%;
  border: 1px solid lightgrey;
}
.slick-prev {
  margin-left: 40px;
}
.slick-next {
  margin-right: 40px;
}
.slick-prev:before {
  content: url('../assets/img/chevron-left.png');
}
.slick-next:before {
  content: url('../assets/img/chevron-right.png');
}
.custom-arrow {
  width: 50px;
  height: 50px;
  z-index: 1;
}
.custom-arrow:hover {
  box-shadow: 0 0 5px lightgrey;
}
.carousel-description {
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  height: 50px;
  background: transparent;
  text-align: center;
  padding: 0;
  cursor: pointer;
}
.decription-link {
  width: fit-content;
  background: dimgrey;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  padding: 0 10px 0 20px;
  border: 3px solid #FFFFFF;
  margin: 0 auto;
  opacity: 0.8;
}
.decription-link:hover {
  color: white;
  border-color: white;
  box-shadow: 0 0 5px lightgrey;
  opacity: 1;
}
@media only screen and (max-width: 1049px) {
  .slick-prev,
  .slick-next {
    display: none;
  }
  .carousel-description {
    top: 60%;
  }
}
@media only screen and (max-width: 600px) {
  .carousel-description {
    top: 55%;
  }
}
</style>

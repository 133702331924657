<template>
  <div id="app" class="app wrapper" v-if="loggedIn">
    <aix-navigation
      :dataUrl="dataUrl"
      style="z-index: 2;"
    />
    <div class="content view-container" style="z-index: 1;">
      <router-view
        :ajaxUrl="ajaxUrl"
        :dataUrl="dataUrl"
        @log-it="logIt($event)"
      />
    </div>
    <aix-footer />
  </div>
  <div v-else class="secret-div">
    <label class="secret-label">
      Please enter your secret:
    </label>
    <input
      type="text"
      id="User"
      v-model="user"
      @keydown.enter="checkSecret"
      placeholder="Username"
      autocomplete="off"
      class="input-secret" />
    <input
      type="password"
      id="Secret"
      v-model="secret"
      @keydown.enter="checkSecret"
      placeholder="Secret"
      autocomplete="off"
      class="input-secret" />
    <input
      type="button"
      value="Validate secret"
      :disabled="checkSecretButtonDisabled"
      @click="checkSecret" />
    <div
      v-if="secretInvalid"
      class="secret-invalid-hint">
      The entered data doesn't match...
    </div>
  </div>
</template>
<script>
import AixNavigation from '@/components/Navigation.vue';
import AixFooter from '@/components/Footer.vue';
import JQuery from 'jquery';
let $ = JQuery;

export default {
  name: 'App',
  components: {
    AixNavigation,
    AixFooter
  },
  data() {
    return {
      ajaxUrl: null,
      dataUrl: null,
      loggedIn: window.location.href.search('localhost:') === -1 && window.location.href.search('aix-fitness.com/stage') === -1,
      // loggedIn: false,
      user: null,
      secret: null,
      secretInvalid: false,
      doLog: window.location.href.search('localhost:') === -1 && window.location.href.search('aix-fitness.com/stage') === -1
    };
  },
  computed: {
    checkSecretButtonDisabled() {
      return !this.user || !this.secret;
    }
  },
  methods: {
    checkStorage() {
      const user = sessionStorage.getItem('user');
      if (user) {
        this.loggedIn = JSON.parse(user).loggedIn;
      }
    },

    checkSecret() {
      if (this.user && this.secret) {
        const url = this.ajaxUrl + 'checkSecret.php';
        const params = {
          user: this.user,
          secret: this.secret
        }

        this.$http.post(url, params, {
          emulateJSON: true
        })
            .then((result) => {
              if (result.data === 'OK') {
                sessionStorage.setItem('user', '{"loggedIn": true, "userId": "' + this.user + '"}');
                this.secretInvalid = false;
                this.loggedIn = true;
                this.$router.go(0);
              } else {
                sessionStorage.setItem('user', '{"loggedIn": false, "userId": null}');
                this.secretInvalid = true;
                this.loggedIn = false;
              }
            })
            .catch(() => {})
      }
    },

    /**
     * method for looging click event
     */
    logIt(logItem) {
      if (this.doLog === true) {
        let url = this.ajaxUrl + 'log.php';
        const params = {
          logItem: logItem
        }

        this.$http.post(url, params, {
          emulateJSON: true
        })
            .then(() => {})
            .catch(() => {})
      }
    }
  },
  beforeCreate() {
    if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      location.href = location.href.replace('http://', 'https://');
    }
  },
  created() {
    if (process.env.NODE_ENV !== 'production') {
      this.loggedIn = true;
    }

    let env = 'dev';
    let publicUrl;
    if (window.location.href.search('/stage') !== -1) {
      env = 'stage';
      publicUrl = 'https://www.aix-fitness.com/stage/';
    } else if (window.location.href.search('www.') !== -1) {
      env = 'prod';
      publicUrl = 'https://www.aix-fitness.com/';
    }

    const localUrl = 'http://localhost/aix-fitness/';

    if (process.env.NODE_ENV === 'production') {
      this.ajaxUrl = this.dataUrl = publicUrl;
    } else {
      this.ajaxUrl = this.dataUrl = localUrl;
    }
    this.ajaxUrl += 'ajax/';
    this.dataUrl += 'data/';

    this.checkStorage();
  },
  mounted() {
    $('.navbar-toggler').on('click', function() {
      $('#navbarNavAltMarkup').slideToggle('fast');
    });

    $('.nav-item').on('click', function() {
      $('#navbarNavAltMarkup').hide();
    });

    /*
    $(document).mouseup(function(e) {
      let nbvam = $('#navbarNavAltMarkup');
      if (!nbvam.is(e.target) &&
          nbvam.has(e.target).length === 0 &&
          !$('.navbar-toggler').is(e.target)
      ) {
        nbvam.slideUp('fast');
      }
    });
     */
  }
}
</script>

<style>
@import 'assets/scss/style.scss';
</style>

<template>
  <header>
    <div id="nav" class="nav">
      <div class="aix-menu">
        <nav class="navbar navbar-expand-lg navbar-light">
          <i class="fas fa-bars navbar-toggler" style="width: 50px;"></i>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <router-link
                v-for="(item, index) in menuList"
                :key="index"
                :to="item.url"
                class="nav-item"
              >
                <div v-if="item.SubItems && item.SubItems.length">
                  <b-dropdown
                    split
                    no-flip
                    :id="item.name"
                    :text="item.name"
                    class="m-md-2 nav-dropdown"
                    :style="activeClassOrNot(item.url)">
                    <template slot="button-content">
                      <i
                        :class="item.iconClass"
                        :title="item.iconTitle"
                      />{{ item.name }}
                    </template>
                    <b-dropdown-item
                      v-for="(subItem, subIndex) in item.SubItems"
                      :key="subIndex"
                      class="nav-dropdown-item"
                      @click="$route.path !== subItem.Url ? $router.push({ path: subItem.Url }) : null"
                    >
                      <router-link
                          :to="subItem.Url"
                          class="nav-item"
                      >
                        {{ subItem.Name }}
                      </router-link>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <a
                  v-else
                  class="r-link"
                >
                  <i
                    :class="item.iconClass"
                    :title="item.iconTitle"
                  />
                  {{ item.name }}
                </a>
              </router-link>
            </div>
          </div>
        </nav>
      </div>
      <div class="aix-brand">
        <a
          href="https://www.instagram.com/p/CKWJsavgE9c/?igshid=1nvcnxnaqr3db"
          target="_blank"
          class="insta-link">
          <img
            :src="dataUrl + 'images/instagram.png'"
            alt="Instagram"
            style="width: 20px; margin-bottom: 4px;"
          />
        </a> | <span class="aix-link">Aix-Fitness</span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'navigation',
  props: {
    dataUrl: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      menuList: [{
        name: 'Home',
        url: '/',
        iconClass: 'fas fa-home',
        iconTitle: 'Home'
      }, {
        name: 'Personal Training',
        url: '/personal-training',
        iconClass: 'fas fa-running',
        iconTitle: 'Personal Training',
        SubItems: [{
          Name: "Beweglichkeit",
          Url: "/personal-training/beweglichkeit"
        }, {
          Name: "Fitness",
          Url: "/personal-training/fitness"
        }, {
          Name: "Gewichtsverlust",
          Url: "/personal-training/gewichtsverlust"
        }, {
          Name: "Muskelaufbau",
          Url: "/personal-training/muskelaufbau"
        }, {
          Name: "Rehabilitation",
          Url: "/personal-training/rehabilitation"
        }, ]
      }, {
        name: 'Ernährung und Gesundheit',
        url: '/ernaehrung-und-gesundheit',
        iconClass: 'fas fa-utensils',
        iconTitle: 'Ernährung und Gesundheit',
        SubItems: [{
          Name: 'Basische Ernährung',
          Url: '/ernaehrung-und-gesundheit/basische-ernaehrung'
        }, {
          Name: 'Ernährungsformen',
          Url: '/ernaehrung-und-gesundheit/ernaehrungsformen'
        }, {
          Name: 'Ernährungspläne',
          Url: '/ernaehrung-und-gesundheit/ernaehrungsplaene'
        }, {
          Name: 'Ernährung bei Übergewicht',
          Url: '/ernaehrung-und-gesundheit/ernaehrung-bei-uebergewicht'
        }, {
          Name: 'Nährstoffanalyse',
          Url: '/ernaehrung-und-gesundheit/naehrstoffanalyse'
        }, {
          Name: 'Nahrungsergänzung',
          Url: '/ernaehrung-und-gesundheit/nahrungsergaenzung'
        }, {
          Name: 'Sporternährung',
          Url: '/ernaehrung-und-gesundheit/sporternaehrung'
        }, ]
      }, {
        name: 'Über mich',
        url: '/ueber-mich',
        iconClass: 'fas fa-user-graduate',
        iconTitle: 'Über mich'
      }, {
        name: 'Blog',
        url: '/blog',
        iconClass: 'fas fa-blog',
        iconTitle: 'Blog'
      }]
    };
  },
  methods: {
    activeClassOrNot(url) {
      if (url === this.$route.path) {
        return 'color: orange !important;';
      }
      return null;
    }
  }
};
</script>

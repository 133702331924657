<template>
  <div v-if="homeData">
    <h1 class="headline">{{ homeData.Headline }}</h1>
    <div style="position: relative;">
      <carousel
          :dataUrl="dataUrl"
          :homeData="homeData"
          class="carousel"
      />
      <div class="carousel-aix">aix-fitness</div>
    </div>
    <div
      class="description"
      v-html="homeData.ShortDescription">
    </div>
    <div
      v-if="homeData && homeData.Aktuelles && homeData.Aktuelles.Aktiv"
      class="aktuelles"
      @click="$router.push({ path: '/aktuelles' })"
    >
      <h2>{{ homeData.Aktuelles.Headline }}</h2>
      {{ homeData.Aktuelles.ShortDescription }}
    </div>
    <div class="row main-topics">
      <div
        class="main-topic-container col-md-5"
        @click="$router.push({ path: homeData.MainTopics[0].Url})"
      >
        <img
          v-lazy="dataUrl + homeData.MainTopics[0].Image"
          :alt="homeData.MainTopics[0].Headline"
          width="100%"
        >
        <div
          class="main-topic-text"
          v-html="homeData.MainTopics[0].ShortDescription + '<i class=\'fas fa-link\'></i>'"
        />
      </div>
      <div class="col-md-2"></div>
      <div
        class="main-topic-container col-md-5"
        @click="$router.push({ path: homeData.MainTopics[1].Url})"
      >
        <img
          v-lazy="dataUrl + homeData.MainTopics[1].Image"
          :alt="homeData.MainTopics[1].Headline"
          width="100%"
        >
        <div
          class="main-topic-text"
          v-html="homeData.MainTopics[1].ShortDescription + '<i class=\'fas fa-link\'></i>'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel';

export default {
  name: 'home',
  components: {
    Carousel
  },
  props: {
    ajaxUrl: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      homeData: null,
      descriptionToggle: false
    };
  },
  methods: {
    emitLogData(page, action) {
      this.$emit(
        'log-it',
        '{"page": "' + page + '", "action": "' + action + '"}'
      );
    }
  },
  created() {
      const url = this.ajaxUrl + 'getData.php?';
      const params = {
        dataUrl: this.dataUrl,
        dataFile: 'home_de'
      }
      this.$http.post(url, params, {
        emulateJSON: true
      })
        .then(response => {
          this.homeData = response.data;
        })
        .catch(() => {})
  },
  mounted() {
    this.emitLogData('home', 'visit');
  },
  beforeDestroy() {
    this.emitLogData('home', 'leave');
  }
}
</script>

<style scoped>
.headline {
  text-align: center;
}
.carousel {
  padding: 0;
}
.carousel-aix {
  position: absolute;
  top: 30%;
  left: 50%;
  width: fit-content;
  height: 50px;
  color: white;
  font-family: 'Bebas Neue', cursive, sans-serif;
  font-size: 3em;
  background: transparent;
  text-align: center;
  padding: 0;
  margin: 0 0 0 -88px;
}
@media only screen and (max-width: 1049px) {
  .carousel-aix {
    top: 20%;
  }
}
@media only screen and (max-width: 600px) {
  .carousel-aix {
    top: 10%;
  }
}

.description {
  font-size: 1.4em;
  padding: 20px 0;
  text-align: center;
}
.main-topic-container {
  border: 1px solid lightgrey;
  padding: 0;
  margin: 0 0 30px 0;
  cursor: pointer;
}
.main-topic-container:hover {
  box-shadow: 0 0 6px #AAAAAA;
}
.main-topics {
  padding: 0 15px;
}
.main-topic-text {
  padding: 10px;
  text-align: center;
}
.aktuelles {
  background: lightblue;
  text-align: center;
  border: 1px solid #87CEFA;
  padding: 0 0 10px;
  margin-bottom: 30px;
  cursor: pointer;
}
</style>

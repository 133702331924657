import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [{
  path: '/',
  name: 'Home',
  component: Home
}, {
  path: '/personal-training',
  name: 'PersonalTraining',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/PersonalTraining.vue')
  }
}, {
  path: '/personal-training/beweglichkeit',
  name: 'Beweglichkeit',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Training/Beweglichkeit.vue')
  }
}, {
  path: '/personal-training/fitness',
  name: 'Fitness',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Training/Fitness.vue')
  }
}, {
  path: '/personal-training/gewichtsverlust',
  name: 'Gewichtsverlust',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Training/Gewichtsverlust.vue')
  }
}, {
  path: '/personal-training/muskelaufbau',
  name: 'Muskelaufbau',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Training/Muskelaufbau.vue')
  }
}, {
  path: '/personal-training/rehabilitation',
  name: 'Rehabilitation',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Training/Rehabilitation.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit',
  name: 'ErnaehrungUndGesundheit',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/ErnaehrungUndGesundheit.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/basische-ernaehrung',
  name: 'Basischernaehrung',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/BasischeErnaehrung.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/ernaehrungsformen',
  name: 'Ernaehrungsformen',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/Ernaehrungsformen.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/ernaehrungsplaene',
  name: 'Ernaehrungsplaene',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/Ernaehrungsplaene.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/ernaehrung-bei-uebergewicht',
  name: 'ernaehrung-bei-uebergewicht',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/ErnaehrungBeiUebergewicht.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/naehrstoffanalyse',
  name: 'Naehrstoffanalys',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/Naehrstoffanalyse.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/nahrungsergaenzung',
  name: 'Nahrungsergaenzung',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/Nahrungsergaenzung.vue')
  }
}, {
  path: '/ernaehrung-und-gesundheit/sporternaehrung',
  name: 'Sporternaehrung',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Ernaehrung/Sporternaehrung.vue')
  }
}, {
  path: '/ueber-mich',
  name: 'UeberMich',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/UeberMich.vue')
  }
}, {
  path: '/blog',
  name: 'Blog',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Blog.vue')
  }
}, {
  path: '/kontakt',
  name: 'Kontakt',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Kontakt.vue')
  }
}, {
  path: '/downloads',
  name: 'Downloads',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Downloads.vue')
  }
}, {
  path: '/impressum',
  name: 'Impressum',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Impressum.vue')
  }
}, {
  path: '/cookies',
  name: 'Cookies',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Cookies.vue')
  }
}, {
  path: '/datenschutz',
  name: 'Datenschutz',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Datenschutz.vue')
  }
}, {
  path: '/aktuelles',
  name: 'Altuelles',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Aktuelles.vue')
  }
}, {
  path: '/error404',
  name: 'Error404',
  component: function () {
    return import(/* webpackChunkName: "about" */ '../views/Error404.vue')
  }
}, {
  path: '*',
  name: 'catchAll',
  component: Home
}];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.ROUTER_BASE,
  // base: process.env.BASE_URL,
  routes
});

export default router;

<template>
  <footer class="footer aix-footer">
    <table class="footer-table">
      <tr>
        <td class="aix-footer-td-left">
          <router-link to="/aktuelles" class="aix-footer-item nav-bottom-item"><i class="fas fa-bell" />Aktuelles</router-link>
        </td>
        <td class="aix-footer-td-right">
          <router-link to="/datenschutz" class="aix-footer-item nav-bottom-item"><i class="fas fa-user-shield" />Datenschutz</router-link><br>
        </td>
      </tr>
      <tr>
        <td class="aix-footer-td-left">
          <router-link to="/kontakt" class="aix-footer-item nav-bottom-item"><i class="fas fa-file-signature" />Kontakt</router-link>
        </td>
        <td class="aix-footer-td-right">
          <router-link to="/cookies" class="aix-footer-item nav-bottom-item"><i class="fas fa-cookie" />Cookies</router-link>
        </td>
      </tr>
      <!--
      <tr>
        <td class="aix-footer-td-left">
          <router-link to="/downloads" class="aix-footer-item nav-bottom-item"><i class="fas fa-file-download" />Downloads</router-link><br>
        </td>
      </tr>
      //-->
      <tr>
        <td class="aix-footer-td-left">
          <router-link to="/impressum" class="aix-footer-item nav-bottom-item"><i class="fas fa-info-circle" />Impressum</router-link>
        </td>
        <td>&nbsp;</td>
      </tr>
    </table>
    <link href="https://fonts.googleapis.com/css?family=Play" rel="stylesheet">
  </footer>
</template>

<script>
import JQuery from 'jquery';
let $ = JQuery;

export default {
  name: 'aix-footer',
  mounted() {
    $('.nav-bottom-item').on('click', function() {
      $('#navbarNavAltMarkup').hide();
    });
  }
};
</script>
